import React, { useEffect, useState } from "react"
import { Map } from "../Components/Map/Map"
import { axiosPrivate } from "../Apis/axios"
import { Truncate } from "../Utils/Validation"
import { Button, Input } from "../Components"
import { Checkbox, IconButton, Tooltip, Button as MUIButton } from "@mui/material"
import { GpsFixed, Loop, Search, Star } from "@mui/icons-material"
import { toast } from "react-toastify"
let MapLoad = require("../images/Mapload.jpg")

function BookingLocation(props: { petInfo?; bookingLocation: CallableFunction, locationList? }) {
    const [store, setStores] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [locationsLoading, setLocationsLoading] = useState(false)
    const [lng, setLng] = useState<any>(40.741895)
    const [lat, setLat] = useState<any>(-73.989308)
    const [locName, setLocName] = useState("")
    const [selectedLocation, setSelectedLocation] = useState()
    const [bookingLocation, setBookingLocation] = useState<any>("")
    const [isTypeable, setIsTypeable] = useState(false)
    const [customerAddress, setCustomerAddress] = useState<any>([
        {
            numCustomerAddressID: 3,
            numAddressTypeID: 1,
            varAddressType: "Home ",
            varCustomerAddress1: "Anghel Daniel MD",
            varCustomerAddress2: "2400 Hempstead Tpke",
            varCustomerCity: "East Meadow",
            varCustomerState: "NY",
            varCustomerZip: "11554",
            varCustomerAddressNotes: "Important Client",
            bitIsPrimaryDefault: true,
            bitIsPrimary: true,
            bitIsBillingDefault: true,
            bitIsBilling: true,
            bitActive: true,
        },
        {
            numCustomerAddressID: 23,
            numAddressTypeID: 1,
            varAddressType: "Home ",
            varCustomerAddress1: "2",
            varCustomerAddress2: "",
            varCustomerCity: "Richmond",
            varCustomerState: "VA",
            varCustomerZip: "23232",
            varCustomerAddressNotes: null,
            bitIsPrimaryDefault: false,
            bitIsPrimary: true,
            bitIsBillingDefault: false,
            bitIsBilling: false,
            bitActive: true,
        },
    ])

    async function getLocations() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }

    const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        name: locName,
    }

    useEffect(() => {
        console.log("petIN", props.petInfo)
    }, [props.petInfo])

    useEffect(() => {
        getLocations()
            .then((res) => {
                const allLocations = [] as any
                res.map((location) => {
                    if (props.petInfo.numPetDefaultGroomLocationID === location.numLocationID) {
                        allLocations.push({
                            longitude: parseFloat(location.varLocationLongitude),
                            latitude: parseFloat(location.varLocationLatitude),
                            title: location.varLocationName,
                            distance: "--",
                            duration: "--",
                            value: 0,
                            img: location.varLocationImagePath,
                            id: location.numLocationID,
                            isPreferred: true,
                        })
                    } else {
                        allLocations.push({
                            longitude: parseFloat(location.varLocationLongitude),
                            latitude: parseFloat(location.varLocationLatitude),
                            title: location.varLocationName,
                            distance: "--",
                            duration: "--",
                            value: 0,
                            img: location.varLocationImagePath,
                            id: location.numLocationID,
                            isPreferred: false,
                        })
                    }
                })
                setStores(allLocations)
                props.locationList(allLocations)
                setLocationsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
        if (props.petInfo.preferredLocation !== "" && props.petInfo.preferredLocation !== null && props.petInfo.preferredLocation !== undefined) {
            BookingLocationSelect(props.petInfo.preferredLocation)
        }
    }, [])

    const getDirections = (salon) => {
        let arr: any = []
        let obj: any = {}
        obj["lat"] = salon.latitude
        obj["lng"] = salon.longitude
        obj["title"] = salon.title
        obj["duration"] = salon.duration
        obj["distance"] = salon.distance
        obj["img"] = salon.img
        arr.push(obj)
        setSelectedLocation(arr)
    }

    const BookingLocationSelect = (id) => {
        setBookingLocation(id)
        props.bookingLocation(id)
    }

    const searchLatAndLngByStreet = (street) => {
        setIsLoading(true)
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ address: street }, (res: any, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                setLat(JSON.stringify(res[0]?.geometry.location.lat()))
                setLng(JSON.stringify(res[0]?.geometry.location.lng()))
                getDistance(JSON.stringify(res[0]?.geometry.location.lat()), JSON.stringify(res[0]?.geometry.location.lng()))
            }
        })
    }

    function getDistance(lati, long) {
        var address = new google.maps.LatLng(lati, long)
        let arr: any = []

        store.map((location, index) => {
            if (!isNaN(location.latitude) && !isNaN(location.longitude)) {
                var locAddress = new google.maps.LatLng(location.latitude, location.longitude)
                arr.push(locAddress)
            }
        })

        let distanceService = new google.maps.DistanceMatrixService()
        distanceService.getDistanceMatrix(
            {
                origins: [address],
                destinations: arr,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                drivingOptions: {
                    departureTime: new Date(),
                    trafficModel: google.maps.TrafficModel.BEST_GUESS,
                },
                avoidHighways: false,
                avoidTolls: false,
            },
            function (response, status) {
                if (status !== google.maps.DistanceMatrixStatus.OK) {
                    console.log("Error:", status)
                } else {
                    console.log("ress", response)

                    if (response?.rows[0].elements[0].status === "ZERO_RESULTS") {
                        console.log("errrrrrr")
                        setIsLoading(true)
                        toast.info("Invalid Gps location")
                    } else {
                        response?.rows[0].elements.map((item, index) => {
                            const editLoc = [...store]
                            let miles = item?.distance.value * 0.000621371192
                            let Display_distance = miles.toFixed(2) + " Miles"
                            editLoc[index].distance = Display_distance
                            editLoc[index].duration = item?.duration.text
                            editLoc[index].value = item?.distance.value
                            setStores(editLoc)
                        })
                        setIsLoading(false)
                    }
                }
            }
        )
    }

    const onSelectedLocation = (salon) => {
        getDirections(salon)
        BookingLocationSelect(salon.id)
    }

    const handleEdit = () => {
        if (isTypeable === true) {
            setIsTypeable(false)
        } else {
            setIsTypeable(true)
        }
    }

    const setLocationAsSelected = (loc) => {
        setLocName(loc)
    }

    const fetchCurrentLocation = () => {
        setIsLoading(true)
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position)
                    setLat(JSON.stringify(position.coords.latitude))
                    setLng(JSON.stringify(position.coords.longitude))
                    getDistance(JSON.stringify(position.coords.latitude), JSON.stringify(position.coords.longitude))
                },
                (error) => {
                    console.log("Error getting location: " + error.message)
                }
            )
        } else {
            console.log("Geolocation is not available in this browser.")
        }
    }

    useEffect(() => {
        console.log("stores", store)
    }, [store])

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: "space-between", margin: "10px", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "15px", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox size="medium" checked={isTypeable} color="primary" onChange={(e) => handleEdit()} />
                            <p>Edit</p>
                        </div>
                        {customerAddress !== undefined && customerAddress !== null ? (
                            <div style={{ width: "100%" }}>
                                <Input
                                    value={locName}
                                    size={"medium"}
                                    outline={true}
                                    variant={"outlined"}
                                    label={"Customer addresses"}
                                    items={
                                        isTypeable
                                            ? null
                                            : customerAddress.map((i) => ({
                                                  text: i.varCustomerAddress1 + " " + i.varCustomerCity + ", " + i.varCustomerState + " " + i.varCustomerZip,
                                                  value: i.varCustomerAddress1 + " " + i.varCustomerCity + ", " + i.varCustomerState,
                                              }))
                                    }
                                    onChange={(e) => {
                                        setLocationAsSelected(e)
                                    }}
                                />
                            </div>
                        ) : (
                            "Loading.."
                        )}
                    </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                    <div
                        style={{ backgroundColor: "#fff", border: "1px solid #2569f1", display: "flex", justifyContent: "center", alignItems: "center", padding: "14px", borderRadius: "8px" }}
                        onClick={() => {
                            fetchCurrentLocation()
                        }}
                    >
                        <GpsFixed style={{ fontSize: "30px", color: "rgb(37, 105, 241)" }} />
                    </div>

                    <div
                        style={{ backgroundColor: "#2569f1", border: "1px solid #2569f1", display: "flex", justifyContent: "center", alignItems: "center", padding: "14px", borderRadius: "8px" }}
                        onClick={() => {
                            searchLatAndLngByStreet(locName)
                        }}
                    >
                        <Search style={{ fontSize: "30px", color: "#FFFFFF" }} />
                    </div>
                </div>
            </div>

            <div style={{ height: "500px", width: "100%", backgroundImage: `url(${MapLoad})` }}>
                {isLoading ? null : <Map location={center} salons={store} directions={true} getRoutes={true} showBaseMarker={true} getDirection={selectedLocation} zoom={20} isSub={false}></Map>}
            </div>

            <div style={{ display: "flex", padding: "0 0 0 1rem", overflowX: "auto", gap: "0.5rem", width: "calc(100%)-1" }}>
                {locationsLoading ? (
                    <p>Loading...</p>
                ) : (
                    store
                        .sort(({ value: a }, { value: b }) => a - b)
                        .sort((salonA, salonB) => {
                            if (salonA.isPreferred && !salonB.isPreferred) {
                                return -1
                            } else if (!salonA.isPreferred && salonB.isPreferred) {
                                return 1
                            } else {
                                return salonA.value - salonB.value
                            }
                        })
                        .map((salon, index) => {
                            return (
                                <div
                                    style={
                                        salon.id === bookingLocation
                                            ? {
                                                  border: "1px solid rgb(37, 105, 241)",
                                                  cursor: "pointer",
                                                  borderRadius: "8px",
                                                  background: "rgb(249, 250, 251)",
                                                  textAlign: "left",
                                                  padding: ".2rem .8rem",
                                                  width: "100%",
                                                  whiteSpace: "nowrap",
                                              }
                                            : {
                                                  cursor: "pointer",
                                                  border: "1px solid rgb(229, 231, 235)",
                                                  borderRadius: "8px",
                                                  background: "rgb(249, 250, 251)",
                                                  textAlign: "left",
                                                  padding: ".2rem .8rem",
                                                  width: "100%",
                                                  whiteSpace: "nowrap",
                                              }
                                    }
                                    key={index}
                                    onClick={() => {
                                        onSelectedLocation(salon)
                                    }}
                                >
                                    <div style={{ display: "flex", gap: "2px", padding: "8px" }}>
                                        <p style={{ margin: "0", fontWeight: "600", fontSize: "18px", lineHeight: "20px", color: "#000000" }}>{Truncate(salon.title, 22)}</p>
                                        {salon.isPreferred ? (
                                            <Tooltip
                                                style={{ paddingLeft: "5px" }}
                                                title={
                                                    <>
                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>Preferred location</p>
                                                    </>
                                                }
                                            >
                                                <Star style={{ fontSize: "18px", color: "#f1c107" }} />
                                            </Tooltip>
                                        ) : null}
                                    </div>

                                    <p style={{ margin: "0", fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#9CA3AF" }}>{salon.distance}</p>
                                    <p style={{ margin: "0", fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#9CA3AF" }}>{salon.duration}</p>
                                </div>
                            )
                        })
                )}
            </div>
        </div>
    )
}

export default BookingLocation
