import { useState, useEffect } from "react"
import { Button, Input } from "../../Components"
import useAuth from "../../Hooks/useAuth"
import styles from "./Register.module.scss"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import { ReactComponent as Logo } from "../../images/logo.svg"
import { useNavigate, useLocation, Link } from "react-router-dom"
import axios from "../../Apis/axios"

let mainLogo = require("../../images/mesh-gradient-vertical.jpg")
const ENDPOINT_URL = "/Auth/Register"

function Register(props) {
    const { auth, setAuth } = useAuth() as any
    const navigate = useNavigate()
    const location = useLocation() as any
    const from = location.state?.from?.pathname || "/"
    const [loggingIn, setLoggingIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [type, setType] = useState(false)

    const [fName, setFName] = useState("")
    const [LName, setLName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [mobile, setMobile] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    useEffect(() => {
        setErrorMessage("")
    }, [username, password])

    const handleToggle = () => {
        setType((prevState) => !prevState)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoggingIn(true)

        try {
            const response = await axios.post(
                ENDPOINT_URL,
                JSON.stringify({
                    varCustomerFirstName: fName,
                    varCustomerLastName: LName,
                    varCustomerEmail: emailAddress,
                    varCustomerMobilePhone: mobile,
                    varCustomerZip: zipCode,
                    varUserName: username,
                    varUserPassword: password,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )

            console.log("registration-success", response.data)
            alert("Registration Successful! Check your provided email addresss to continue.")

            setUsername("")
            setPassword("")

            setLoggingIn(false)
        } catch (err: any) {
            console.log(err, "error")
            if (!err?.response) {
                // toast.error("No response from the server");
                alert("No response from the server")
            } else if (err?.response?.status === 400) {
                if (err?.response?.status === 400)
                    // toast.error("Unauthorized");
                    alert("Unauthorized")
            } else {
                // toast.error("Login Failed");
                alert("Login Failed")
            }
            setLoggingIn(false)
        }
    }
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "79px",
                    alignItems: "center",
                    padding: "0 20px",
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eee",
                }}
            >
                <div>
                    <Logo />
                </div>
                <div style={{ fontSize: "16px", fontWeight: "400", color: "#606D93" }}>
                    Already a member?{" "}
                    <span style={{ fontSize: "16px", fontWeight: "700", color: "#2076FF" }}>
                        <Link to={"/login"}>Sign In</Link>
                    </span>
                </div>
            </div>
            <div className={styles.responsiveLayout} style={{ display: "flex", height: "calc(100vh - 80px)" }}>
                <div
                    className={styles.formWrapper}
                    style={{ flex: 1, display: "flex", alignItems: "center", height: "100%", justifyContent: "center", flexDirection: "column", backgroundColor: "#fff" }}
                >
                    <div style={{ width: "50%" }}>
                        <h1 className={styles.headline} style={{ color: "#192252" }}>
                            Welcome to ESyPet <br></br>Sign Up to get started.
                        </h1>
                        <p className={styles.subtitle} style={{ color: "#606D93" }}>
                            Enter your details to get started
                        </p>
                    </div>
                    <div style={{ width: "70%" }}>
                        <form onSubmit={handleSubmit}>
                            <div style={{ width: "100%" }}>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setFName(val)
                                    }}
                                    label={"First name"}
                                    value={fName}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setLName(val)
                                    }}
                                    value={LName}
                                    label={"Last name"}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setEmailAddress(val)
                                    }}
                                    value={emailAddress}
                                    label={"Email"}
                                    type={"email"}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setMobile(val)
                                    }}
                                    value={mobile}
                                    label={"Mobile"}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setZipCode(val)
                                    }}
                                    value={zipCode}
                                    label={"Zip Code"}
                                    type={"number"}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setUsername(val)
                                    }}
                                    value={username}
                                    label={"Username"}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setPassword(val)
                                    }}
                                    value={password}
                                    label={"Password"}
                                    type={type ? "text" : "password"}
                                />
                                <div className={styles.spacer}></div>
                                <Input
                                    className={styles.inputs}
                                    onChange={(val) => {
                                        setConfirmPassword(val)
                                    }}
                                    value={confirmPassword}
                                    label={"Confirm Password"}
                                    type={type ? "text" : "password"}
                                />

                                <div className={styles.eyeIcon} onClick={handleToggle}>
                                    {type ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                </div>

                                <Button color="primary" type="submit" loading={loggingIn} className={styles.loginBtn} variant={"contained"}>
                                    Get Started
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={styles.bgWrapper} style={{ flex: 1, backgroundImage: `url(${mainLogo})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
            </div>
        </>
    )
}

export default Register
