import { useState, useEffect } from "react";
import { Button, Input } from "../../Components";
import useAuth from "../../Hooks/useAuth";
import styles from "./Login.module.scss";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "../../Apis/axios";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import {Logo}  from '../../images/logo.svg'
import { ReactComponent as Logo } from '../../images/logo.svg';
let mainLogo = require('../../images/mesh-gradient-vertical.jpg');
const ENDPOINT_URL = "/Auth";

const Login = (props: {
  onLoginSuccess?
}) => {
  const { auth, setAuth } = useAuth() as any;
  const navigate = useNavigate();
  const location = useLocation() as any;
  const from = location.state?.from?.pathname || "/";
  const [loggingIn, setLoggingIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState(false);


  useEffect(() => {
    setErrorMessage("");
  }, [username, password]);

  const handleToggle = () => {
    setType((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoggingIn(true);

    try {
      const response = await axios.post(ENDPOINT_URL, JSON.stringify({ username, password }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      console.log("loginn-success", response.data);

      const accessToken = response?.data?.token;
      const roles = [123, 456, 789];

      setAuth({ username, password, roles, accessToken, loggedIn: true });
      props.onLoginSuccess(response.data);
      setUsername("");
      setPassword("");
      setLoggingIn(false);
    } catch (err: any) {
      console.log(err, "error");
      if (!err?.response) {
        // toast.error("No response from the server");
        alert("No response from the server");
      } else if (err?.response?.status === 400) {
        if (err?.response?.status === 400)
          // toast.error("Unauthorized");
          alert("Unauthorized Login")
      } else {
        // toast.error("Login Failed");
        alert("Login Failed");
      }
      setLoggingIn(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', height: '79px', alignItems: 'center', padding: '0 20px', backgroundColor: '#fff', borderBottom: '1px solid #eee' }}>
        <div><Logo /></div>
        <div style={{ fontSize: "16px", fontWeight: "400", color: "#606D93" }}>New member? <span style={{ fontSize: "16px", fontWeight: "700", color: "#2076FF" }}><Link to={'/register'}>Sign Up</Link></span></div>
      </div>
      <div className={styles.responsiveLayout} style={{ display: 'flex', height: 'calc(100vh - 80px)' }}>
        <div className={styles.formWrapper} style={{ flex: 1, display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', flexDirection: 'column', backgroundColor: '#fff' }}>
          <div style={{ width: '50%' }}>
            <h1 className={styles.headline} style={{ color: '#192252' }}>Welcome to ESyPet <br></br>Sign In to get started!</h1>
            <p className={styles.subtitle} style={{ color: '#606D93' }}>Enter your details to proceed further. If you are a new user click on the top right link to register yourself first.</p>
          </div>
          <div className={styles.loginContainer} style={{ width: '50%' }}>
            <form onSubmit={handleSubmit} >
              <div style={{ width: '100%' }}>
                <Input
                  className={styles.inputs}
                  onChange={(val) => {
                    setUsername(val);
                  }}
                  type="email"
                  label={"Username"}
                  value={username}
                />
                <div className={styles.spacer}></div>
                <Input
                  className={styles.inputs}
                  onChange={(val) => {
                    setPassword(val);
                  }}
                  value={password}
                  label={"Password"}
                  type={type ? "text" : "password"}

                />

                <div className={styles.eyeIcon} onClick={handleToggle}>
                  {type ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </div>
                  <div style={{width:"100%", marginTop:"10px"}}>
                    <Button
                      color="primary"
                      type="submit"
                      loading={loggingIn}
                      className={styles.loginBtn}
                      variant={"contained"}
                  >
                      Sign In
                  </Button>
                  </div>
                
              </div>
            </form>
          </div>

        </div>
        <div className={styles.bgWrapper} style={{ flex: 1, backgroundImage: `url(${mainLogo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

        </div>
      </div>
      <div className={styles.footer}>
            Copyright©{" "}
            <a href="https://www.esyntaxis.com/" target="_blank">
              ESyntaxis Corporation
            </a>
            . ESyPet Version {process.env.REACT_APP_VERSION}
          </div>
    </>
  );
};

export default Login;
