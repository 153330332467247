import { DragIndicator } from "@mui/icons-material";
import { Button, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-drag-reorder";
import axios from "axios"
import { toast } from "react-toastify";

const Notifications = (props: {
    data?;
    type;
    index?;
    mode?: boolean;
}) => {

    const [isEmail, setIsEmail] = useState(false)
    const [isSMS, setIsSMS] = useState(false)
    const [loading, setLoading] = useState(false)

    const [draggableKey, setDraggableKey] = useState("")


    const [list, setList] = useState([
        {
            mode: "SMS", 
            type: "Mobile",
            status: true,
            name: "S"
        },       
        {
            mode: "Email", 
            type: "",
            status: true,
            name: "E"
        },
        {
            mode: "Call", 
            type: "Mobile",
            status: true,
            name: "C"
        },
        {
            mode: "Call", 
            type: "Home",
            status: true,
            name: "H"
        },
        {
            mode: "Call", 
            type: "Work",
            status: false,
            name: "W"
        },
    ])

    async function getOrder(cusID) {
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}CommunicationPriorityOrder/GetCommunicationPriorityOrder?CustomerID=${cusID}`).then((result) => {
            if (result.status === 200) {

                let savedObj = result?.data[0]
              
                const convertedObject = [
                    {
                        id: "numCustomerNotificationMobilePhoneSMSSequence",
                        mode: "SMS",
                        type: "Mobile",
                        status: (savedObj.bitCustomerNotificationMobilePhoneSMS === "") ? false : savedObj.bitCustomerNotificationMobilePhoneSMS,
                        name: "S"
                    },
                    {
                        id: "numCustomerNotificationEmailSequence",
                        mode: "Email",
                        type: "",
                        status: (savedObj.bitCustomerNotificationEmail === "") ? false : savedObj.bitCustomerNotificationEmail,
                        name: "E"
                    },
                    {
                        id: "numCustomerNotificationMobilePhoneCallSequence",
                        mode: "Call",
                        type: "Mobile",
                        status: (savedObj.bitCustomerNotificationMobilePhoneCall === "") ? false : savedObj.bitCustomerNotificationMobilePhoneCall,
                        name: "C"
                    },
                    {
                        id: "numCustomerNotificationHomePhoneSequence",
                        mode: "Call",
                        type: "Home",
                        status: (savedObj.bitCustomerNotificationHomePhone === "") ? false : savedObj.bitCustomerNotificationHomePhone,
                        name: "H"
                    },
                    {
                        id: "numCustomerNotificationWorkPhoneSequence",
                        mode: "Call",
                        type: "Work",
                        status: (savedObj.bitCustomerNotificationWorkPhone === "") ? false : savedObj.bitCustomerNotificationWorkPhone,
                        name: "W"
                    }
                ]                                              

                const sortedObject = convertedObject.sort((a, b) => {
                    const sequenceA = savedObj[a.id];
                    const sequenceB = savedObj[b.id];
                    return sequenceA - sequenceB;
                  });
                
                setList(sortedObject) 

                // console.log(savedObj)
                // console.log(sortedObject)

                // setMessages(result?.data[0])
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            toast.error('Something went wrong!');
        })
    }

    const getChangedPos = (currentPos, newPos) => {
        if (currentPos === newPos) {
            return; 
        }
        
        const newList = [...list];
        const item = newList.splice(currentPos, 1)[0];
        newList.splice(newPos, 0, item);
        setList(newList);        
    };

    const updateOrder = (index) => {
        const updatedList = [...list]
        updatedList[index] = { ...updatedList[index], status: !updatedList[index].status }
        setList(updatedList)        
    }

    useEffect(() => {
        setDraggableKey(Date.now().toString())
    }, [list])

    useEffect(() => {
        getOrder(props.data?.customer?.numCustomerID)
    }, [])

    async function sendPriority(data) {
        await axios.put(`${process.env.REACT_APP_API_URL}CommunicationPriorityOrder/UpdateCommunicationPriority`,
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            if (result.status === 200) {
                toast.success(`Notification priorities changed successfully!`)                
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }

    const postNewOrder = () => {
        let sendObj = {
            "customerID": props.data?.customer?.numCustomerID,
            "bitNotificationEmail": list[list.findIndex(item => item.name === "E")].status,
            "numNotificationEmailSequence": list.findIndex(item => item.name === "E") + 1,
            "bitNotificationHomePhone": list[list.findIndex(item => item.name === "H")].status,
            "numNotificationHomePhoneSequence": list.findIndex(item => item.name === "H") + 1,
            "bitNotificationMobilePhoneCall": list[list.findIndex(item => item.name === "C")].status,
            "numNotificationMobilePhoneCallSequence": list.findIndex(item => item.name === "C") + 1,
            "bitNotificationMobilePhoneSMS": list[list.findIndex(item => item.name === "S")].status,
            "numNotificationMobilePhoneSMSSequence": list.findIndex(item => item.name === "S") + 1,
            "bitNotificationWorkPhone": list[list.findIndex(item => item.name === "W")].status,
            "numNotificationWorkPhoneSequence": list.findIndex(item => item.name === "W") + 1
        }

        console.log(sendObj)
        sendPriority(sendObj)        
    }

    
    return (
        <>
            <div style={{ paddingTop: '2rem' }}>
                <div style={{ display: 'flex', gap: '2rem' }}>
                    <div style={{ width: '40%'}}>
                        <p style={{ color: '#000', fontWeight: '600', margin: '0px' }}>Notification Priorities</p>
                        <p style={{ color: '#777E90' }}>Drag to position the notification priorities in the way the customer prefers</p>
                    </div>

                    {
                        (loading) ? 
                        <p>Loading...</p>
                        : 
                        <div>
                            <Draggable key={draggableKey} onPosChange={ (currentPos, newPos) => getChangedPos(currentPos, newPos) }>
                                {
                                    list.map((word, idx) => {
                                        return (
                                            <div key={idx} style={{ backgroundColor: '#EFF5FF', padding: '.5rem 1rem', borderRadius: '4px', marginBottom: '.5rem' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{display:"flex", gap: '.5rem', alignItems: 'center'}}>
                                                        <Checkbox 
                                                            style={{ padding: '0 .2rem 0 0'}} 
                                                            onChange={() => {
                                                                updateOrder(idx)                                                            
                                                            }}  
                                                            checked={list[idx].status} 
                                                        />

                                                        <p style={{ color: '#002867' }}>
                                                            <b>{ word.mode }</b> - { word.type } 
                                                        </p>                                                  
                                                    </div>    
                                                    <DragIndicator style={{ marginLeft: '1rem', color: '#9CA3AF' }} />  
                                                </div>                                            
                                            </div>
                                        )
                                    })
                                }
                            </Draggable>                                           
                        </div>
                    }

                   
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Button variant="contained" onClick={() => postNewOrder()}>Save Changes</Button>
                        </div>
            </div>
        </>
    )
}

export default Notifications;